<template>
  <div
    v-for="displayGroup in displayGroupsDisplay"
    :key="displayGroup.slug"
    :class="[displayGroup.slug === 'busca' ? '-mt-48' : '']"
  >
    <ProductsGrid
      :banner="displayGroup.banner"
      :title="displayGroup.name"
      :slug="displayGroup.slug"
      :countdown="displayGroup.date_end"
      :countdown-date-end="displayGroup.date_end"
      :hide-title="displayGroup.sale_conditions.length === 0 && displayGroup.slug !== 'busca'"
      :will-expand="
        displayGroup.collapse &&
        displayGroup.sale_conditions.length >= displayGroup.display_items &&
        !isOpen[displayGroup.slug]
      "
    >
      <div
        v-for="(id, index) in displayGroup.sale_conditions.slice(
          0,
          displayGroup.collapse ? calculateItemsToDisplay(displayGroup) : Infinity,
        )"
      >
        <SaleConditionRasgaPreco
          v-if="sale_conditions[id]?.selling_method?.settings?.rasga_preco"
          :saleCondition="sale_conditions[id]"
          :image-loading="index === 0 ? 'eager' : 'lazy'"
        />

        <SaleCondition
          v-else
          :saleCondition="sale_conditions[id]"
          :image-loading="index === 0 ? 'eager' : 'lazy'"
        >
          <AddToCart :saleConditionId="id" />
        </SaleCondition>
      </div>

      <DisplayGroupsExtended
        v-if="
          displayGroup.collapse &&
          displayGroup.sale_conditions.length > calculateItemsToDisplay(displayGroup)
        "
        :skip="calculateItemsToDisplay(displayGroup)"
        :displayGroup="displayGroup"
        :open-callback="openCallback"
      />
    </ProductsGrid>
  </div>
</template>

<script lang="ts" setup>
import AddToCart from '@/components/AddToCart.vue'
import DisplayGroupsExtended from '@/components/DisplayGroupsExtended.vue'
import ProductsGrid from '@/components/ProductsGrid.vue'
import SaleCondition from '@/components/SaleCondition.vue'
import SaleConditionRasgaPreco from '@/components/SaleConditionRasgaPreco.vue'
import { display_groups, sale_conditions } from '../../data/default.json'
import { computed, onMounted, type Ref, ref } from 'vue'
import { intQuantityPluralized } from '&/services/format'
import { openSaleConditionDialog } from '@/services/dynamicDialog'
import { validate as isValidUUID } from 'uuid'
import { searchTerm, searchResults } from '@/services/search'
import { useStore } from '@nanostores/vue'

const search = useStore(searchTerm)
const results = useStore(searchResults)
const isOpen: Ref<{ [key: string]: boolean }> = ref(
  display_groups.reduce(
    (a: any, b: any) => {
      b[a.slug] = false
      return b
    },
    { search: true },
  ),
)
const windowSize: Ref<'sm' | 'md' | 'lg'> = ref('sm')

const displayGroupsDisplay = computed(() => {
  if (!search.value || !results.value) {
    return display_groups.filter(({ sale_conditions, banner }) => sale_conditions.length || banner)
  } else {
    return [
      {
        name: intQuantityPluralized(
          results.value.length,
          'Resultado encontrado',
          'Resultados encontrados',
        ),
        emoji: '',
        date_end: null,
        banner: null,
        collapse: false,
        display_items: 0,
        slug: 'busca',
        sale_conditions: results.value.map((result) => result.id),
      },
    ]
  }
})

function openCallback(group: string, value: boolean) {
  isOpen.value[group] = value
}

function calculateItemsToDisplay(displayGroup: any): number {
  let itemsToDisplay = displayGroup.display_items
  if (itemsToDisplay < 1 || itemsToDisplay === Infinity) {
    return itemsToDisplay
  }

  const totalPages = itemsToDisplay / 2
  if (windowSize.value === 'md') {
    itemsToDisplay = 4 * totalPages
  } else if (windowSize.value === 'lg') {
    itemsToDisplay = 6 * totalPages
  }

  return itemsToDisplay
}

function windowSizeHandler() {
  if (window.innerWidth < 768) {
    windowSize.value = 'sm'
  } else if (window.innerWidth < 1024) {
    windowSize.value = 'md'
  } else {
    windowSize.value = 'lg'
  }
}

onMounted(() => {
  const searchParams = new URLSearchParams(window.location.search)
  const productParam = searchParams.get('p')
  if (productParam) {
    if (productParam in sale_conditions) {
      openSaleConditionDialog(sale_conditions[productParam])
    }
  }

  if (window.location.hash) {
    const currentHash = window.location.hash.replace('#', '')
    if (isValidUUID(currentHash) && currentHash in sale_conditions) {
      openSaleConditionDialog(sale_conditions[currentHash])
    }
  }

  window.addEventListener('resize', windowSizeHandler)
  windowSizeHandler()
})
</script>
